export const casinoTabs = {
  roulettetab: {
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
    },
    DiamondRoulette: {
      href: "/casino/ezugi/DiamondRoulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/diamond-roulette1.png", alt: "" },
    },
    NamasteRoulette: {
      href: "/casino/ezugi/NamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/mamaste-roulette1.png", alt: "" },
    },
    TurkishRoulette: {
      href: "/casino/ezugi/TurkishRoulette",
      code: "5501000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/turkish-roulette.png", alt: "" },
    },
    AutoRoulette: {
      href: "/casino/ezugi/AutoRoulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
    },
    SpeedAutoRoulette: {
      href: "/casino/ezugi/SpeedAutoRoulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/speed-auto-roulette.png", alt: "" },
    },
    OracleRoulette2: {
      href: "/casino/ezugi/OracleRoulette2",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/oracle-roulette-2.png", alt: "" },
    },
    PortomasoRoulette1: {
      href: "/casino/ezugi/PortomasoRoulette1",
      code: "611004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/portomaso-roulette-1.jpg", alt: "" },
    },
    ItalianRoulette: {
      href: "/casino/ezugi/ItalianRoulette",
      code: "1000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/italian-roulette.jpg", alt: "" },
    },
    SpanishRoulette: {
      href: "/casino/ezugi/SpanishRoulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/spanish-roulette1.jpg", alt: "" },
    },
    RussianRoulette: {
      href: "/casino/ezugi/RussianRoulette",
      code: "601000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/russian-roulette.jpg", alt: "" },
    },
    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/fiesta-roulette1.jpg", alt: "" },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/prestige-auto-oulette.jpg", alt: "" },
    },
    HandCasinoHoldem: {
      href: "/casino/evolution/HandCasinoHoldem",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/11.png", alt: "" },
    },
  },
  baccarattab: {
    Baccaratpro: {
      href: "/casino/ezugi/Baccaratpro",
      code: "100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat01.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/ezugi/Nocommissiobaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat02.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/ezugi/Knockoutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat03.png", alt: "" },
    },
    Super6baccarat: {
      href: "/casino/ezugi/Super6baccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat04.png", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat05.png", alt: "" },
    },

    Marina02baccarat: {
      href: "/casino/ezugi/Marina02baccarat",
      code: "32101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat06.png", alt: "" },
    },
    SpeedCricketBaccarat: {
      href: "/casino/ezugi/SpeedCricketBaccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat07.png", alt: "" },
    },
    Baccarat: {
      href: "/casino/ezugi/Baccarat",
      code: "106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat08.png", alt: "" },
    },
    Baccaratpro02: {
      href: "/casino/ezugi/Baccaratpro02",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat09.png", alt: "" },
    },
    SpeedBaccarat: {
      href: "/casino/ezugi/SpeedBaccarat",
      code: "1000021",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    },
    Marina03baccarat: {
      href: "/casino/ezugi/Marina03baccarat",
      code: "32103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    },
  },
  AndarBahartab: {
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar01.png", alt: "" },
    },
    OTTAndarBahar: {
      href: "/casino/ezugi/OTTAndarBahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar02.png", alt: "" },
    },
    UltimateAB: {
      href: "/casino/ezugi/UltimateAB",
      code: "228100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar02.png", alt: "" },
    },
  },
  TeenPattitab: {
    TeenPatti01: {
      href: "/casino/ezugi/TeenPatti01",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker01.png", alt: "" },
    },
    TeenPatti02: {
      href: "/casino/ezugi/TeenPatti02",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker02.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/ezugi/TeenPatti03",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker03.png", alt: "" },
    },
    TeenPatti04: {
      href: "/casino/ezugi/TeenPatti04",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker04.png", alt: "" },
    },
    TeenPatti05: {
      href: "/casino/ezugi/TeenPatti05",
      code: "1000115",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker05.png", alt: "" },
    },
    TeenPatti06: {
      href: "/casino/ezugi/TeenPatti06",
      code: "1000111",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker06.jpeg", alt: "" },
    },
    TeenPatti07: {
      href: "/casino/ezugi/TeenPatti07",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker07.png", alt: "" },
    },
    TeenPatti08: {
      href: "/casino/ezugi/TeenPatti08",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker08.png", alt: "" },
    },
  },
  Blackjacktab: {
    Blackjack: {
      href: "/casino/ezugi/Blackjack",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack01.png", alt: "" },
    },
    GoldBlackjack: {
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack02.png", alt: "" },
    },
    BlackjackPro: {
      href: "/casino/ezugi/BlackjackPro",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack03.png", alt: "" },
    },
    VIPBlackjack: {
      href: "/casino/ezugi/VIPBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack04.png", alt: "" },
    },
    BlackjackAutoSplit: {
      href: "/casino/ezugi/BlackjackAutoSplit",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack05.png", alt: "" },
    },
    TurkceBlackjack: {
      href: "/casino/ezugi/TurkceBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack06.png", alt: "" },
    },
    RussianBlackjack2: {
      href: "/casino/ezugi/RussianBlackjack2",
      code: "426",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack07.png", alt: "" },
    },
    UnlimitedBlackjack: {
      href: "/casino/ezugi/UnlimitedBlackjack",
      code: "51",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack09.png", alt: "" },
    },
    DiamondBlackjack: {
      href: "/casino/ezugi/DiamondBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack010.png", alt: "" },
    },
    PlatinumBlackjack1: {
      href: "/casino/ezugi/PlatinumBlackjack1",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    BlackjackParty: {
      href: "/casino/ezugi/BlackjackParty",
      code: "1000040",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    Vip02Blackjack: {
      href: "/casino/ezugi/Vip02Blackjack",
      code: "1000135",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack013.png", alt: "" },
    },
    TurkishBlackjack: {
      href: "/casino/ezugi/TurkishBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack015.png", alt: "" },
    },
    TurkishBlackjack2: {
      href: "/casino/ezugi/TurkishBlackjack2",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    BlackjackTurkce: {
      href: "/casino/ezugi/BlackjackTurkce",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    PlatinumBlackjack: {
      href: "/casino/ezugi/PlatinumBlackjack",
      code: "5051",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack019.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/32cards01.png", alt: "" },
    },
  },
  sevenLuckytab: {
    sevenLucky: {
      href: "/casino/ezugi/sevenLucky",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/Lucky011.png", alt: "" },
    },
  },
  sicbotab: {
    SicBo: {
      href: "/casino/ezugi/SicBo",
      code: "224000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice01.png", alt: "" },
    },
    SuperSicBo: {
      href: "/casino/ezugi/SuperSicBo",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice02.png", alt: "" },
    },
    UltimateSicBo: {
      href: "/casino/ezugi/UltimateSicBo",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice03.png", alt: "" },
    },
    sicbodice04: {
      href: "/casino/ezugi/sicbodice04",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice04.jpeg", alt: "" },
    },
    sicbodice05: {
      href: "/casino/ezugi/sicbodice05",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice05.png", alt: "" },
    },
  },
  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      pilnko: {
        href: "/casino/spribe/pilnko",
        code: "pilnko",
        casino: "spribe",
        provider: "pilnko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "miniroulette",
        casino: "spribe",
        provider: "miniroulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hilo",
        casino: "miniroulette",
        provider: "hilo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },

    ChineseTreasures: {
      href: "/casino/ezugi/ChineseTreasures",
      code: "1100006",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuck: {
      href: "/casino/ezugi/DragonLuck",
      code: "1100001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonFire: {
      href: "/casino/ezugi/DragonFire",
      code: "1100008",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuckDeluxe: {
      href: "/casino/ezugi/DragonLuckDeluxe",
      code: "1100011",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonLuckReel: {
      href: "/casino/ezugi/DragonLuckReel",
      code: "1100002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    EpicJourney: {
      href: "/casino/ezugi/EpicJourney",
      code: "1100012",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GodWealth: {
      href: "/casino/ezugi/GodWealth",
      code: "1100005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyFortune: {
      href: "/casino/ezugi/LuckyFortune",
      code: "1100010",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MagicGate: {
      href: "/casino/ezugi/MagicGate",
      code: "1100009",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTemple: {
      href: "/casino/ezugi/GoldenTemple",
      code: "1100013",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PhoenixPower: {
      href: "/casino/ezugi/PhoenixPower",
      code: "1100014",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiratesPlenty: {
      href: "/casino/ezugi/PiratesPlenty",
      code: "1100015",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenOffer: {
      href: "/casino/ezugi/GoldenOffer",
      code: "1100016",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RAsLegend: {
      href: "/casino/ezugi/RAsLegend",
      code: "1100017",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GonzoMegawaya: {
      href: "/casino/ezugi/GonzoMegawaya",
      code: "1100018                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RainbowJAcket: {
      href: "/casino/ezugi/RainbowJAcket",
      code: "1100019                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaPyramid: {
      href: "/casino/ezugi/MegaPyramid",
      code: "1100020                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Ozzy_Osbourne: {
      href: "/casino/ezugi/Ozzy_Osbourne",
      code: "1100024                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jimi_Hendrix: {
      href: "/casino/ezugi/Jimi_Hendrix",
      code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jimi_Hendrix: {
      href: "/casino/ezugi/Jimi_Hendrix",
      code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Jumanji: {
      href: "/casino/ezugi/Jumanji",
      code: "1100027                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MotorheadVideoSlot: {
      href: "/casino/ezugi/MotorheadVideoSlot",
      code: "1100028                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Starburst: {
      href: "/casino/ezugi/Starburst",
      code: "1100029                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WarofGod: {
      href: "/casino/ezugi/WarofGod",
      code: "1100030                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Christmas: {
      href: "/casino/ezugi/Christmas",
      code: "1100032                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ClusterPays: {
      href: "/casino/ezugi/ClusterPays",
      code: "1100033                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AncientsBlessing: {
      href: "/casino/ezugi/AncientsBlessing",
      code: "1100034                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AurumCodex: {
      href: "/casino/ezugi/AurumCodex",
      code: "1100035                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GunsNRoses: {
      href: "/casino/ezugi/GunsNRoses",
      code: "1100036                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BorisandBoo: {
      href: "/casino/ezugi/BorisandBoo",
      code: "1100037                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWest: {
      href: "/casino/ezugi/WildWest",
      code: "1100038                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SteamTower: {
      href: "/casino/ezugi/SteamTower",
      code: "1100043                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ZeusLightning: {
      href: "/casino/ezugi/ZeusLightning",
      code: "1100044                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WingsofRa: {
      href: "/casino/ezugi/WingsofRa",
      code: "1100045                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Trillionaire: {
      href: "/casino/ezugi/Trillionaire",
      code: "1100047                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TreasureMine: {
      href: "/casino/ezugi/TreasureMine",
      code: "1100048                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ThorVengeance: {
      href: "/casino/ezugi/ThorVengeance",
      code: "1100049                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RegalStreak: {
      href: "/casino/ezugi/RegalStreak",
      code: "1100050                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RegalBeasts: {
      href: "/casino/ezugi/RegalBeasts",
      code: "1100051                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKeeper: {
      href: "/casino/ezugi/ReelKeeper",
      code: "1100053                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RainbowJackpotsPowerLines: {
      href: "/casino/ezugi/RainbowJackpotsPowerLines",
      code: "1100055                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiggyRichesMegaways: {
      href: "/casino/ezugi/PiggyRichesMegaways",
      code: "1100059                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleBells: {
      href: "/casino/ezugi/JingleBells",
      code: "1100065                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackinaPot: {
      href: "/casino/ezugi/JackinaPot",
      code: "1100066                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FiveStar: {
      href: "/casino/ezugi/FiveStar",
      code: "1100068                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BountyRaid: {
      href: "/casino/ezugi/BountyRaid",
      code: "1100069                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MysteryReels2: {
      href: "/casino/ezugi/MysteryReels2",
      code: "1100061                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DiamondBlitz: {
      href: "/casino/ezugi/DiamondBlitz",
      code: "1100072                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DragonFireMegaways: {
      href: "/casino/ezugi/DragonFireMegaways",
      code: "1100074                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Rome: {
      href: "/casino/ezugi/Rome",
      code: "1100075                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelRush: {
      href: "/casino/ezugi/ReelRush",
      code: "1100076                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BloodSuckers: {
      href: "/casino/ezugi/BloodSuckers",
      code: "1100077                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Pyramid: {
      href: "/casino/ezugi/Pyramid",
      code: "1100078                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ButterflyStaxx: {
      href: "/casino/ezugi/ButterflyStaxx",
      code: "1100079                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PiggyRiches: {
      href: "/casino/ezugi/PiggyRiches",
      code: "1100080                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DarkKing: {
      href: "/casino/ezugi/DarkKing",
      code: "1100081                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleSpin: {
      href: "/casino/ezugi/JingleSpin",
      code: "1100082                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dazzle: {
      href: "/casino/ezugi/Dazzle",
      code: "1100083                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackHammer: {
      href: "/casino/ezugi/JackHammer",
      code: "1100084                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive: {
      href: "/casino/ezugi/DeadorAlive",
      code: "1100085                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackandtheBeanstalk: {
      href: "/casino/ezugi/JackandtheBeanstalk",
      code: "1100086                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive2: {
      href: "/casino/ezugi/DeadorAlive2",
      code: "1100087                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivineFortuneMegaways: {
      href: "/casino/ezugi/DivineFortuneMegaways",
      code: "1100089                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GordonRamsayHellsKitchen: {
      href: "/casino/ezugi/GordonRamsayHellsKitchen",
      code: "1100090                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Elements: {
      href: "/casino/ezugi/Elements",
      code: "1100091                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GonzosQuest: {
      href: "/casino/ezugi/GonzosQuest",
      code: "1100092                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FinnandtheSwirlySpin: {
      href: "/casino/ezugi/FinnandtheSwirlySpin",
      code: "1100093                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShop: {
      href: "/casino/ezugi/FruitShop",
      code: "1100095                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    BloodSuckers2: {
      href: "/casino/ezugi/BloodSuckers2",
      code: "1100097                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ButterflyStaxx2: {
      href: "/casino/ezugi/ButterflyStaxx2",
      code: "1100098                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMeMegaways: {
      href: "/casino/ezugi/DazzleMeMegaways",
      code: "1100099                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DeadorAlive2FeatureBuy: {
      href: "/casino/ezugi/DeadorAlive2FeatureBuy",
      code: "1100100                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DoubleStacks: {
      href: "/casino/ezugi/DoubleStacks",
      code: "1100101                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FairytaleLegends: {
      href: "/casino/ezugi/FairytaleLegends",
      code: "1100102                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Flowers: {
      href: "/casino/ezugi/Flowers",
      code: "1100103                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopChristmasEdition: {
      href: "/casino/ezugi/FruitShopChristmasEdition",
      code: "1100104                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Hotline: {
      href: "/casino/ezugi/Hotline",
      code: "1100107                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Hotline2: {
      href: "/casino/ezugi/Hotline2",
      code: "1100108                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheInvisibleMan: {
      href: "/casino/ezugi/TheInvisibleMan",
      code: "1100109                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    IrishPotLuck: {
      href: "/casino/ezugi/IrishPotLuck",
      code: "1100110                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JackHammer2: {
      href: "/casino/ezugi/JackHammer2",
      code: "1100111                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitSpin: {
      href: "/casino/ezugi/FruitSpin",
      code: "1100112                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JungleSpirit: {
      href: "/casino/ezugi/JungleSpirit",
      code: "1100113                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    KoiPrincess: {
      href: "/casino/ezugi/KoiPrincess",
      code: "1100114                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DriveMultiplierMayhem: {
      href: "/casino/ezugi/DriveMultiplierMayhem",
      code: "1100115                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSeas: {
      href: "/casino/ezugi/RageoftheSeas",
      code: "1100116                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RichesofMidgard: {
      href: "/casino/ezugi/RichesofMidgard",
      code: "1100117                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ScruffyDuck: {
      href: "/casino/ezugi/ScruffyDuck",
      code: "1100118                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SecretoftheStones: {
      href: "/casino/ezugi/SecretoftheStones",
      code: "1100119                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SecretsofChristmas: {
      href: "/casino/ezugi/SecretsofChristmas",
      code: "1100120                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SerengetiKings: {
      href: "/casino/ezugi/SerengetiKings",
      code: "1100121                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SpaceWars: {
      href: "/casino/ezugi/SpaceWars",
      code: "1100122                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Victorious: {
      href: "/casino/ezugi/Victorious",
      code: "1100123                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Warlords: {
      href: "/casino/ezugi/Warlords",
      code: "1100125                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWater: {
      href: "/casino/ezugi/WildWater",
      code: "1100126                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildWorlds: {
      href: "/casino/ezugi/WildWorlds",
      code: "1100127                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Squad4: {
      href: "/casino/ezugi/Squad4",
      code: "1100129                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Families5: {
      href: "/casino/ezugi/Families5",
      code: "1100130                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AgentRoyale: {
      href: "/casino/ezugi/AgentRoyale",
      code: "1100131                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ArcadeBomb: {
      href: "/casino/ezugi/ArcadeBomb",
      code: "1100132                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Atlantis: {
      href: "/casino/ezugi/Atlantis",
      code: "1100133                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    AztecSpins: {
      href: "/casino/ezugi/AztecSpins",
      code: "1100134                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CinderellaBall: {
      href: "/casino/ezugi/CinderellaBall",
      code: "1100135                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CirquedelaFortune: {
      href: "/casino/ezugi/CirquedelaFortune",
      code: "1100136                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ClashoftheBeasts: {
      href: "/casino/ezugi/ClashoftheBeasts",
      code: "1100137                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CrazyGenie: {
      href: "/casino/ezugi/CrazyGenie",
      code: "1100138                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CrystalMirror: {
      href: "/casino/ezugi/CrystalMirror",
      code: "1100139                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DaVinciMystery: {
      href: "/casino/ezugi/DaVinciMystery",
      code: "1100140                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DevilNumber: {
      href: "/casino/ezugi/DevilNumber",
      code: "1100141                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DiceDice: {
      href: "/casino/ezugi/DiceDice",
      code: "1100142                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FiveStarsPowerReels: {
      href: "/casino/ezugi/FiveStarsPowerReels",
      code: "1100144                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GemsGoneWild: {
      href: "/casino/ezugi/GemsGoneWild",
      code: "1100145                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GemsGoneWildPowerReels: {
      href: "/casino/ezugi/GemsGoneWildPowerReels",
      code: "1100146                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenLeprechaunMegaways: {
      href: "/casino/ezugi/GoldenLeprechaunMegaways",
      code: "1100148                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    HoardofPoseidon: {
      href: "/casino/ezugi/HoardofPoseidon",
      code: "1100151                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JewelScarabs: {
      href: "/casino/ezugi/JewelScarabs",
      code: "1100152                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    JingleBellsPowerReels: {
      href: "/casino/ezugi/JingleBellsPowerReels",
      code: "1100153                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LegendofAthena: {
      href: "/casino/ezugi/LegendofAthena",
      code: "1100154                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyHalloween: {
      href: "/casino/ezugi/LuckyHalloween",
      code: "1100155                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyLittleDevil: {
      href: "/casino/ezugi/LuckyLittleDevil",
      code: "1100156                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyValentine: {
      href: "/casino/ezugi/LuckyValentine",
      code: "1100157                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    LuckyWizard: {
      href: "/casino/ezugi/LuckyWizard",
      code: "1100158                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Masquerade: {
      href: "/casino/ezugi/Masquerade",
      code: "1100159                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MayanGods: {
      href: "/casino/ezugi/MayanGods",
      code: "1100160                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaDragon: {
      href: "/casino/ezugi/MegaDragon",
      code: "1100161                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaJade: {
      href: "/casino/ezugi/MegaJade",
      code: "1100162                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MegaRise: {
      href: "/casino/ezugi/MegaRise",
      code: "1100163                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MultiplierRiches: {
      href: "/casino/ezugi/MultiplierRiches",
      code: "1100164                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MysteryReelsMegaways: {
      href: "/casino/ezugi/MysteryReelsMegaways",
      code: "1100165                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    NinjaWays: {
      href: "/casino/ezugi/NinjaWays",
      code: "1100166                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PathofDestiny: {
      href: "/casino/ezugi/PathofDestiny",
      code: "1100167                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    PersianFortune: {
      href: "/casino/ezugi/PersianFortune",
      code: "1100168                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelHeist: {
      href: "/casino/ezugi/ReelHeist",
      code: "1100169                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RobinHoodWildForest: {
      href: "/casino/ezugi/RobinHoodWildForest",
      code: "1100170                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SnowWild: {
      href: "/casino/ezugi/SnowWild",
      code: "1100171                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    SylvanSpirits: {
      href: "/casino/ezugi/SylvanSpirits",
      code: "1100172                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheGreatestTrainRobbery: {
      href: "/casino/ezugi/TheGreatestTrainRobbery",
      code: "1100173                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TheWildHatter: {
      href: "/casino/ezugi/TheWildHatter",
      code: "1100174                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ThorLightning: {
      href: "/casino/ezugi/ThorLightning",
      code: "1100175                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TikiFruits: {
      href: "/casino/ezugi/TikiFruits",
      code: "1100176                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TotemLightning: {
      href: "/casino/ezugi/TotemLightning",
      code: "1100177                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VaultCracker: {
      href: "/casino/ezugi/VaultCracker",
      code: "1100178                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VaultofAnubis: {
      href: "/casino/ezugi/VaultofAnubis",
      code: "1100179                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WellofWishes: {
      href: "/casino/ezugi/WellofWishes",
      code: "1100180                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildCatsMultiline: {
      href: "/casino/ezugi/WildCatsMultiline",
      code: "1100181                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildOClock: {
      href: "/casino/ezugi/WildOClock",
      code: "1100182                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WildChest: {
      href: "/casino/ezugi/WildChest",
      code: "1100183                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WinEscalator: {
      href: "/casino/ezugi/WinEscalator",
      code: "1100184                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    YucatanMystery: {
      href: "/casino/ezugi/YucatanMystery",
      code: "1100185                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },
};

export const HomeGamesData = {
  PopularGames: {
    Aviator: {
      href: "/casino/spribe/aviator",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Aviator",
      homeUrl: "",
      cover: { src: "./images/popular/home/Aviator.jpg", alt: "" },
    },
    AndarBahar: {
      href: "/casino/ezg-andar-bahar",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Andar Bahar",
      cover: { src: "./images/popular/home/andarbahar.jpg", alt: "" },
    },
    AutoRoulette: {
      href: "/casino/ezg-auto-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Auto Roulette",
      cover: { src: "./images/popular/home/autoroulette.jpg", alt: "" },
    },
    SpeedRoulette: {
      href: "/casino/ezg-speed-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed Roulette",
      cover: { src: "./images/popular/home/SpeedRoulette.webp", alt: "" },
    },
    LightningRoulette: {
      href: "/casino/ezgevo-lightning-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Lightning Roulette",
      cover: { src: "./images/popular/home/lightningRoulette.jpg", alt: "" },
    },
    DragonTiger: {
      href: "/casino/ezgevo-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Dragon Tiger",
      cover: { src: "./images/popular/home/DragonTigerEvo.jpg", alt: "" },
    },
    BaccaratEzg: {
      href: "/casino/ezg-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Baccarat",
      cover: { src: "./images/popular/home/Baccarat.webp", alt: "" },
    },
    Lucky7Ezg: {
      href: "/casino/ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Crazy Time",
      cover: { src: "./images/popular/home/lucky7.jpg", alt: "" },
    },
    CrazyTime: {
      href: "/casino/ezgevo-crazy-time",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Crazy Time",
      cover: { src: "./images/popular/home/CrazyTime.jpg", alt: "" },
    },
    OneDayTeenPatti: {
      href: "/casino/ezg-one-day-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "One Day Teen Patti",
      cover: { src: "./images/popular/home/OneDayTeenPatti.jpg", alt: "" },
    },

    // UltimateRoulette: {
    //   href: "/casino/ezg-ultimate-roulette",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Ultimate Roulette",
    //   cover: { src: "./images/popular/home/UltimateRoulette.jpg", alt: "" },
    // },
    // AndarBaharVivo: {
    //   href: "/casino/vivo-andar-bahar",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Andar Bahar",
    //   cover: { src: "./images/popular/home/andarbaharvivo.jpg", alt: "" },
    // },
    // TeenPattiVivo: {
    //   href: "/casino/vivo-teen-patti",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   name: "Speed Auto Roulette",
    //   homeUrl: "",
    //   cover: { src: "./images/popular/home/Teenpatti.jpg", alt: "" },
    // },
    // SpeedAutoRoulette: {
    //   href: "/casino/ezg-speed-auto-roulette",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   name: "Speed Auto Roulette",
    //   homeUrl: "",
    //   cover: { src: "./images/popular/home/SpeedAutoROulette.jpg", alt: "" },
    // },
    // NamasteRoulette: {
    //   href: "/casino/ez-namaste-roulette",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Namaste Roulette",
    //   cover: { src: "./images/popular/home/namsterouletet.jpg", alt: "" },
    // },
    // TeenPatti3Card: {
    //   href: "/casino/ezg-teen-patti-3-card",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Teen Patti 3 Card",
    //   cover: { src: "./images/popular/home/TeenPatti3Card.jpg", alt: "" },
    // },
    // SpeedCricketBaccarat: {
    //   href: "/casino/ezg-speed-cricket-baccarat",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Speed Cricket Baccarat",
    //   cover: { src: "./images/popular/home/SpeedCricketBacarat.jpg", alt: "" },
    // },
    // Sicbo: {
    //   href: "/casino/ezg-sic-bo",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Sicbo",
    //   cover: { src: "./images/popular/home/Sicbo.jpg", alt: "" },
    // },
    // Casinoholdem: {
    //   href: "/casino/ezg-casino-holdem",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Casino Holdem",
    //   cover: { src: "./images/popular/home/casinoholdem.jpg", alt: "" },
    // },
    // InfiniteBlackjack: {
    //   href: "/casino/ezgevo-infinite-blackjack",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Infinite Blackjack",
    //   cover: { src: "./images/popular/home/infiniteblack.jpg", alt: "" },
    // },
    // CashorCrash: {
    //   href: "/casino/ezgevo-cash-or-crash",
    //   code: "221000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Cash or Crash",
    //   cover: { src: "./images/popular/home/CashOrCrash.jpg", alt: "" },
    // },
  },

  AndarBaharGames: {
    AndarBahar: {
      href: "/casino/ezg-andar-bahar",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Andar Bahar",
      cover: { src: "./images/popular/home/andarbahar.jpg", alt: "" },
    },
    CasinoMarinaAndarBahar: {
      href: "/casino/ezg-ultimate-andar-bahar",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Casino Marina Andar Bahar",

      cover: {
        src: "./images/andarbahar/home/CasinoMarinaAndarBahar.jpg",
        alt: "",
        name: "Andar Bahar",
      },
    },
    UltimateAndarBahar: {
      href: "/casino/ez-namaste-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/andarbahar/home/UltimateAndarBahar.jpg",
        alt: "",
      },
      name: "Ultimate Andar Bahar",
    },
    SuperAndarBahar: {
      href: "/casino/ev-super-andar-bahar",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Super Andar Bahar",
      cover: { src: "./images/andarbahar/home/SuperAndarBahar.jpg", alt: "" },
    },
  },

  RouletteGames: {
    UltimateRoulette: {
      href: "/casino/ezg-ultimate-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Ultimate Roulette",
      cover: { src: "./images/popular/home/UltimateRoulette.jpg", alt: "" },
    },
    DiamondRoulette: {
      href: "/casino/ez-diamond-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Diamond Roulette",
      cover: { src: "./images/roulette/home/diamondroulete.jpg", alt: "" },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ez-prestige-auto-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/home/PrestigeAutoRoulette.jpg",
        alt: "",
      },
      name: "Prestige Auto Roulette",
    },
    SpanishRoulette: {
      href: "/casino/ezg-spanish-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Spanish Roulette",
      cover: { src: "./images/roulette/home/spanishrou.jpg", alt: "" },
    },
    ItalianRoulette: {
      href: "/casino/ez-italian-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/home/IlailianRoulette.jpg", alt: "" },
      name: "Italian Roulette ",
    },
    RussianRoulette: {
      href: "/casino/ezg-russian-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Russian Roulette  ",
      cover: { src: "./images/roulette/home/RussianROulette.jpg", alt: "" },
    },
    RuletaDelSolRoulette: {
      href: "/casino/ezg-ruleta-del-sol",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Ruleta Del Sol Roulette  ",
      cover: { src: "./images/roulette/home/Ruletadelsol.jpg", alt: "" },
    },
    InstantRoulette: {
      href: "/casino/ezgevo-instant-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Instant Roulette   ",
      cover: { src: "./images/roulette/home/instantRoulette.jpg", alt: "" },
    },
    ImmersiveRoulette: {
      href: "/casino/evo-immersive-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Immersive Roulette   ",
      cover: { src: "./images/roulette/home/ImmersiveROultte.jpg", alt: "" },
    },
    AmericanRoulette: {
      href: "/casino/ezgevo-american-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "American Roulette",
      cover: { src: "./images/roulette/home/AmericanRoulette.jpg", alt: "" },
    },
    DoubleBallRoulette: {
      href: "/casino/ezgevo-double-ball-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Double Ball Roulette ",
      cover: { src: "./images/roulette/home/DoubleBallRoulette.jpg", alt: "" },
    },
    TurkishLightningRoulette: {
      href: "/casino/ezgevo-turkish-lightning-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/home/TurkishLightningRoulette.jpg",
        alt: "",
      },
      name: "Turkish Lightning Roulette ",
    },
    FiestaRoulette: {
      href: "/casino/	ezg-fiesta-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Fiesta Roulette ",
      cover: { src: "./images/roulette/home/FiestaRoulette.jpg", alt: "" },
    },
  },

  BaccaratGames: {
    FortuneBaccarat: {
      href: "/casino/ezg-fortune-baccarat",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Fortune Baccarat",
      cover: { src: "./images/baccarat/home/fortunebaccarat.jpg", alt: "" },
    },
    Super6Baccarat: {
      href: "/casino/ezg-super-6-baccarat",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Super 6 Baccarat",
      cover: { src: "./images/baccarat/home/super6baccarat.jpg", alt: "" },
    },
    NoCommissionBaccarat: {
      href: "/casino/ezg-no-commission-baccarat",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "No Commission Baccarat",

      cover: {
        src: "./images/baccarat/home/NoCommissionBaccarat.jpg",
        alt: "",
      },
    },
    CasinoMarinaBaccarat1: {
      href: "/casino/ezg-casino-marina-baccarat-1",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Casino Marina Baccarat 1",
      homeUrl: "",
      cover: {
        src: "./images/baccarat/home/CasinoMarinaBaccarat1.jpg",
        alt: "",
      },
    },
    CasinoMarinaBaccarat2: {
      href: "/casino/ezg-casino-marina-baccarat-2",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Casino Marina Baccarat 2",
      homeUrl: "",
      cover: {
        src: "./images/baccarat/home/CasinoMarinaBaccarat2.jpg",
        alt: "",
      },
    },
    CasinoMarinaBaccarat3: {
      href: "/casino/ezg-casino-marina-baccarat-3",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Casino Marina Baccarat 3",
      homeUrl: "",
      cover: {
        src: "./images/baccarat/home/CasinoMarinaBaccarat3.jpg",
        alt: "",
      },
    },
    CasinoMarinaBaccarat4: {
      href: "/casino/ezg-casino-marina-baccarat-4",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Casino Marina Baccarat 4",
      homeUrl: "",
      cover: {
        src: "./images/baccarat/home/CasinoMarinaBaccarat4.jpg",
        alt: "",
      },
    },
    BaccaratKnockout: {
      href: "/casino/ezg-knockout-baccarat",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Baccarat Knockout",
      homeUrl: "",
      cover: { src: "./images/baccarat/home/BaccaratKnockout.jpg", alt: "" },
    },
    PeekBaccarat: {
      href: "/casino/ezgevo-peek-baccarat",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Peek Baccarat",
      homeUrl: "",
      cover: { src: "./images/baccarat/home/PeekBaccarat.jpg", alt: "" },
    },
    FirstPersonLightningBaccarat: {
      href: "/casino/ezgevo-first-person-lightning-baccarat",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "First Personal Lightning Baccarat",
      homeUrl: "",
      cover: {
        src: "./images/baccarat/home/FirstPersonLightningBaccarat.jpg",
        alt: "",
      },
    },
    LightningBaccarat: {
      href: "/casino/ezgevo-lightning-baccarat",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "",
      homeUrl: "Lightning Baccarat",
      cover: { src: "./images/baccarat/home/LightningBaccarat.jpg", alt: "" },
    },
    BaccaratA: {
      href: "/casino/ezgevo-baccarat-a",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "",
      homeUrl: "Baccarat A",
      cover: { src: "./images/baccarat/home/BaccaratA.jpg", alt: "" },
    },
    BaccaratB: {
      href: "/casino/ezgevo-baccarat-b",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Baccarat B",
      homeUrl: "",
      cover: { src: "./images/baccarat/home/BaccaratB.jpg", alt: "" },
    },
    BaccaratC: {
      href: "/casino/ezgevo-baccarat-c",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Baccarat C",
      homeUrl: "",
      cover: { src: "./images/baccarat/home/BaccaratC.jpg", alt: "" },
    },
    BaccaratSqueeze: {
      href: "/casino/ezgevo-baccarat-squeeze",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Baccarat Squeeze",
      homeUrl: "",
      cover: { src: "./images/baccarat/home/BaccaratSqueeze.jpg", alt: "" },
    },
    NoCommissionSpeedBaccaratA: {
      href: "/casino/ezgevo-no-commission-speed-baccarat-a",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "No Commission Speed Baccarat A",
      homeUrl: "",
      cover: {
        src: "./images/baccarat/home/NoCommissionSpeedBaccaratA.jpg",
        alt: "",
      },
    },
    SalonPriveBaccaratB: {
      href: "/casino/evo-salon-prive-baccarat-b",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Salon Prive Baccarat B",
      homeUrl: "",
      cover: { src: "./images/baccarat/home/SalonPriveBaccaratB.jpg", alt: "" },
    },
    SpeedBaccaratA: {
      href: "/casino/evo-speed-baccarat-a",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Speed Baccarat A",
      homeUrl: "",
      cover: { src: "./images/baccarat/home/SpeedBaccaratA.jpg", alt: "" },
    },
    SpeedBaccaratB: {
      href: "/casino/ezgevo-speed-baccarat-b",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Speed Baccarat B",
      homeUrl: "",
      name: "Speed Baccarat B",
      cover: { src: "./images/baccarat/home/SpeedBaccaratB.jpg", alt: "" },
    },
  },

  DragonTigerGames: {
    DragonTiger: {
      href: "/casino/ezg-dragon-tiger",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Dragon Tiger",
      cover: { src: "./images/dragontiger/home/DragonTigerEZG.jpg", alt: "" },
    },
    DragonTigerEvolution: {
      href: "/casino/ezgevo-dragon-tiger-evo",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Dragon Tiger Evolution",
      cover: { src: "./images/dragontiger/home/DragonTiger.jpg", alt: "" },
    },
    FirstPersonDragonTiger: {
      href: "/casino/ezgevo-first-person-dragon-tiger",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/dragontiger/home/FirstPersonDragonTiger.jpg",
        alt: "",
      },
      name: "First Person Dragon Tiger",
    },
  },

  BlackjackGames: {
    // BlackjackA: {
    //   href: "/casino/ezg-blackjack-a",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Blackjack A",
    //   cover: { src: "./images/blackjack/home/BlackjackA.webp", alt: "Blackjack A" },
    // },
    BlackjackDaSorte: {
      href: "/casino/ezg-blackjack-da-sorte",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Da Sorte",
      cover: {
        src: "./images/blackjack/home/BlackjackDaSorte.webp",
        alt: "Blackjack Da Sorte",
      },
    },

    BlackjackAEvo: {
      href: "/casino/ezgevo-blackjack-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack A",
      cover: {
        src: "./images/blackjack/home/BlackjackAEvo.webp",
        alt: "Blackjack A Evo",
      },
    },
    BlackjackPartyEvo: {
      href: "/casino/ezgevo-blackjack-party",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Party",
      cover: {
        src: "./images/blackjack/home/BlackjackParty.webp",
        alt: "Blackjack Party",
      },
    },
    BlackjackSilverAEvo: {
      href: "/casino/ezgevo-blackjack-silver-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Silver A",
      cover: {
        src: "./images/blackjack/home/BlackjackSilverA.webp",
        alt: "Blackjack Silver A",
      },
    },
    BlackjackVIP10Evo: {
      href: "/casino/ezgevo-blackjack-vip-10",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack VIP 10",
      cover: {
        src: "./images/blackjack/home/BlackjackVIP10.webp",
        alt: "Blackjack VIP 10",
      },
    },
    BlackjackVIPAlphaEvo: {
      href: "/casino/ezgevo-blackjack-vip-alpha",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack VIP Alpha",
      cover: {
        src: "./images/blackjack/home/BlackjackVIPAlpha.webp",
        alt: "Blackjack VIP Alpha",
      },
    },
    BlackjackVIPBetaEvo: {
      href: "/casino/ezgevo-blackjack-vip-beta",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack VIP Beta",
      cover: {
        src: "./images/blackjack/home/BlackjackVIPBeta.webp",
        alt: "Blackjack VIP Beta",
      },
    },
    BlackjackVIPEEvo: {
      href: "/casino/ezgevo-blackjack-vip-i",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack VIP E",
      cover: {
        src: "./images/blackjack/home/BlackjackVIPI.webp",
        alt: "Blackjack VIP E",
      },
    },
    BlackjackVIPGammaEvo: {
      href: "/casino/ezgevo-blackjack-vip-gamma",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack VIP Gamma",
      cover: {
        src: "./images/blackjack/home/BlackjackVIPGamma.webp",
        alt: "Blackjack VIP Gamma",
      },
    },
    BlackjackVIPLEvo: {
      href: "/casino/ezgevo-blackjack-vip-l",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack VIP L",
      cover: {
        src: "./images/blackjack/home/BlackjackVIPL.webp",
        alt: "Blackjack VIP L",
      },
    },
    ClassicSpeedBlackjack10Evo: {
      href: "/casino/ezgevo-classic-speed-blackjack-10",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Classic Speed Blackjack 10",
      cover: {
        src: "./images/blackjack/home/ClassicSpeedBlackjack10.webp",
        alt: "Classic Speed Blackjack 10",
      },
    },
    FirstPersonBlackjackEvo: {
      href: "/casino/ezgevo-first-person-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "First Person Blackjack",
      cover: {
        src: "./images/blackjack/home/FirstPersonBlackjack.webp",
        alt: "First Person Blackjack",
      },
    },
    FirstPersonLightningBlackjackEvo: {
      href: "/casino/ezgevo-first-person-lightning-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "First Person Lightning Blackjack",
      cover: {
        src: "./images/blackjack/home/FirstPersonLightningBlackjack.webp",
        alt: "First Person Lightning Blackjack",
      },
    },
    InfiniteBlackjackEvo: {
      href: "/casino/ezgevo-infinite-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Infinite Blackjack",
      cover: {
        src: "./images/blackjack/home/InfiniteBlackjack.webp",
        alt: "Infinite Blackjack",
      },
    },
    LightningBlackjackEvo: {
      href: "/casino/ezgevo-lightning-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Lightning Blackjack",
      cover: {
        src: "./images/blackjack/home/LightningBlackjack.webp",
        alt: "Lightning Blackjack",
      },
    },
    PowerBlackjackEvo: {
      href: "/casino/ezgevo-power-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Power Blackjack",
      cover: {
        src: "./images/blackjack/home/PowerBlackjack.webp",
        alt: "Power Blackjack",
      },
    },
    SpeedBlackjackDEvo: {
      href: "/casino/ezgevo-speed-blackjack-d",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed Blackjack D",
      cover: {
        src: "./images/blackjack/home/SpeedBlackjackD.webp",
        alt: "Speed Blackjack D",
      },
    },
    SpeedVIPBlackjackAEvo: {
      href: "/casino/ezgevo-speed-vip-blackjack-a",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed VIP Blackjack A",
      cover: {
        src: "./images/blackjack/home/SpeedVIPBlackjackA.webp",
        alt: "Speed VIP Blackjack A",
      },
    },
  },

  SlotsGames: {
    DragonsLuck: {
      href: "/casino/ezgrt-dragons-luck",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Dragons Luck",
      cover: { src: "./images/slots/home/dRAGONSlUCK.jpg", alt: "" },
    },
    MegaDragon: {
      href: "/casino/ezgrt-mega-dragon",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Mega Dragon",
      homeUrl: "",
      cover: { src: "./images/slots/home/MegaDragon.jpg", alt: "" },
    },
    DragonsLuckPowerReels: {
      href: "/casino/ezgrt-dragons-luck-power-reels",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Dragons Luck Power Reels",
      homeUrl: "",
      cover: { src: "./images/slots/home/DragonsLuckPowerReels.jpg", alt: "" },
    },
    Nights10001: {
      href: "/casino/ezgrt-10001-nights",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Nights 10001",
      homeUrl: "",
      cover: { src: "./images/slots/home/Nights10001.jpg", alt: "" },
    },
    PiratesPlentyBattleforGold: {
      href: "/casino/ezgrt-pirates-plenty-battle-for-gold",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Pirates Plenty Battle for Gold",
      homeUrl: "",
      cover: {
        src: "./images/slots/home/PiratesPlentyBattleforGold.jpg",
        alt: "",
      },
    },
    GodOfWealth: {
      href: "/casino/ezgrt-god-of-wealth",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "God of Wealth",
      homeUrl: "",
      cover: { src: "./images/slots/home/GodOfWealth.jpg", alt: "" },
    },
    ChineseTreasures: {
      href: "/casino/ezgrt-chinese-treasures",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Chinese Treasures",
      homeUrl: "",
      cover: { src: "./images/slots/home/ChineseTreasures.jpg", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezgrt-fortune-house",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Fortune House",
      homeUrl: "",
      cover: { src: "./images/slots/home/FortuneHouse.jpg", alt: "" },
    },
    MagicGate: {
      href: "/casino/ezgrt-magic-gate",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Magic Gate",
      homeUrl: "",
      cover: { src: "./images/slots/home/MagicGate.jpg", alt: "" },
    },
    AlohaChristmas: {
      href: "/casino/ezgne-aloha-christmas",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Aloha Christmas",
      homeUrl: "",
      cover: { src: "./images/slots/home/AlohaChristmas.jpg", alt: "" },
    },
    AlohaClusterPays: {
      href: "/casino/ezgne-aloha-cluster-pays",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Aloha Cluster Pays",
      homeUrl: "",
      cover: { src: "./images/slots/home/AlohaClusterPays.jpg", alt: "" },
    },
    WildWildWest: {
      href: "/casino/ezgne-wild-wild-west-the-great-train-heist",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Wild Wild West",
      homeUrl: "",
      cover: {
        src: "./images/slots/home/WildWildWestTheGreatTrainHeist.jpg",
        alt: "",
      },
    },
    TwinSpinMegaways: {
      href: "/casino/ezgne-twin-spin-megaways",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Twin Spin Mega Ways",
      homeUrl: "",
      cover: { src: "./images/slots/home/TwinSpinMegaways.jpg", alt: "" },
    },
    TurnYourFortune: {
      href: "/casino/ezgne-turn-your-fortune",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Turn Your Fortune",
      homeUrl: "",
      cover: { src: "./images/slots/home/TurnYourFortune.jpg", alt: "" },
    },
    SteamTower: {
      href: "/casino/ezgne-steam-tower",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Steam Tower",
      homeUrl: "",
      cover: { src: "./images/slots/home/SteamTower.jpg", alt: "" },
    },
    Rome: {
      href: "/casino/ezgne-rome-the-golden-age",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Rome",
      homeUrl: "",
      cover: { src: "./images/slots/home/RomeTheGoldenAge.jpg", alt: "" },
    },
    ReelRush: {
      href: "/casino/ezgne-reel-rush",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Reel Rush",
      homeUrl: "",
      cover: { src: "./images/slots/home/ReelRush.jpg", alt: "" },
    },
    BloodSuckers: {
      href: "/casino/	ezgne-blood-suckers",
      code: "221000",
      casino: "ezugi",
      provider: "",
      name: "Blood Suckers",
      homeUrl: "",
      cover: { src: "./images/slots/home/BloodSuckers.jpg", alt: "" },
    },
  },

  GameShows: {
    DreamCatcher: {
      href: "/casino/ezgevo-dream-catcher",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Dream Catcher",
      cover: { src: "./images/gameshow/home/DreamCatcher.jpg", alt: "" },
    },
    DeadorAlive: {
      href: "/casino/ezgevo-dead-or-alive-saloon",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Dead or Alive",
      cover: { src: "./images/gameshow/home/deadoralivesaloon.jpg", alt: "" },
    },
    FootballStudio: {
      href: "/casino/ezgevo-football-studio",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Football Studio",
      cover: { src: "./images/gameshow/home/FootballStudio.jpg", alt: "" },
    },
    Megaball: {
      href: "/casino/ezgevo-mega-ball",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Megaball",
      cover: { src: "./images/gameshow/home/megaball.jpg", alt: "" },
    },
    LightningDice: {
      href: "/casino/ezgevo-lightning-dice",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Lightning Dice",
      cover: { src: "./images/gameshow/home/lightningdice.jpg", alt: "" },
    },
    LightningRoulette: {
      href: "/casino/ezgevo-lightning-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Lightning Roulette",
      cover: { src: "./images/gameshow/home/lightningRoulette.jpg", alt: "" },
    },
    Lucky7: {
      href: "/casino/ezg-lucky-7",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Lucky 7",
      cover: { src: "./images/gameshow/home/lucky7.jpg", alt: "" },
    },
    BetOnTeenPatti: {
      href: "/casino/ezg-bet-on-teen-patti",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Bet On Teen Patti",
      cover: { src: "./images/gameshow/home/betonteenpatti.jpg", alt: "" },
    },
    XXXtremeLightningRoulette: {
      href: "/casino/ezgevo-xxxtreme-lightning-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/gameshow/home/XXXtremeLightningRoulette.jpg",
        alt: "",
      },
      name: "XXXtreme Lightning Roulette",
    },
    FirstPersonMegaBall: {
      href: "/casino/ezgevo-first-person-mega-ball",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "First Person MegaBall",
      cover: { src: "./images/gameshow/home/FirstPersonMegaBall.jpg", alt: "" },
    },
  },

  TeenPattiGames: {
    TeenPatti3Card: {
      href: "/casino/ezg-teen-patti-3-card",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Teen Patti 3 Card",
      cover: { src: "./images/teenpatti/home/TeenPatti3Card.jpg", alt: "" },
    },
    BetonTeenPatti: {
      href: "/casino/ezg-bet-on-teen-patti",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Bet on Teen Patti",
      cover: { src: "./images/teenpatti/home/betonteenpatti.jpg", alt: "" },
    },
    OneDayTeenPatti: {
      href: "/casino/ezg-one-day-teen-patti",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "One Day Teen Patti",
      cover: { src: "./images/teenpatti/home/OneDayTeenPatti.jpg", alt: "" },
    },
  },

  ProvidersGames: {
    evolution: {
      code: "",
      casino: "ezugi",
      href: "/casino/evolution",
      provider: "EVO_LOBBY",
      homeUrl: "",
      name: "Evolution",
      cover: { src: "./images/providers/home/Evolution.jpg", alt: "" },
    },
    ezugi: {
      href: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Ezugi",
      cover: { src: "./images/providers/home/Ezugi.jpg", alt: "" },
    },
    supernowa: {
      href: "/casino/supernowa",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      games: {},
      name: "Supernowa",
      cover: { src: "./images/providers/home/Supernowa.jpg", alt: "" },
    },
    worldcasino: {
      href: "/casino/worldcasino",
      code: "",
      casino: "wco",
      provider: "",
      homeUrl: "",
      games: {},
      name: "World casino",
      cover: { src: "./images/providers/home/WorldCasino.jpg", alt: "" },
    },
    xpg: {
      href: "/casino/xpg",
      code: "",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      games: {},
      name: "Xpg",
      cover: { src: "./images/providers/home/XPG.jpg", alt: "" },
    },
    onetouch: {
      href: "/casino/onetouch",
      code: "",
      casino: "wco",
      provider: "OT",
      homeUrl: "",
      games: {},
      name: "onetouch",
      cover: { src: "./images/providers/home/OneTouch.jpg", alt: "" },
    },
    onlyplay: {
      href: "/casino/onlyplay",
      code: "",
      casino: "wco",
      provider: "GT",
      homeUrl: "",
      games: {},
      name: "Onlyplay",
      cover: { src: "./images/providers/home/Onlyplay.jpg", alt: "" },
    },
    pragmaticplay: {
      href: "/casino/pragmaticplay",
      code: "",
      casino: "wco",
      provider: "PP",
      homeUrl: "",
      games: {},
      name: "Pragmatic Play",
      cover: { src: "./images/providers/home/Pragamatic.jpg", alt: "" },
    },
    qtech: {
      href: "/casino/qtech",
      code: "",
      casino: "wco",
      provider: "QT",
      homeUrl: "",
      games: {},
      name: "Qtech",
      cover: { src: "./images/providers/home/Qtech.jpg", alt: "" },
    },
    vivo: {
      href: "/casino/vivo",
      code: "vivo",
      casino: "vivo",
      provider: "",
      homeUrl: "",
      name: "Vivo",
      cover: { src: "./images/providers/home/vivo.jpg", alt: "" },
    },
    kingmaker: {
      href: "/kingmaker",
      code: "vivo",
      casino: "vivo",
      provider: "",
      homeUrl: "",
      name: "Vivo",
      cover: { src: "./images/providers/home/Kingmaker.jpg", alt: "" },
    },
  },
};
